<script setup lang="ts">
import type { FooterSecondBlockProps } from './FooterSecondBlock.props'
const { dispatchCookieBannerEvent } = useGANavigationEvents()
const { openDialog } = useDialog()
const { cookies, acceptAll, save, toggleCookie } = useOneTrustCookies()

defineProps<FooterSecondBlockProps>()

const emit = defineEmits<{
  onLinkClick: [section: string, id: string | undefined]
}>()

const cookieBannerCenter = 'modal-cookie-banner-center'

const onLinkClick = (title: string, trackId?: string) => {
  if (trackId && trackId?.includes('cookie-settings')) {
    dispatchCookieBannerEvent('preferences')
    openDialog(cookieBannerCenter)
  } else {
    emit('onLinkClick', title, trackId)
  }
}

const acceptAllCookies = () => {
  acceptAll()
  dispatchCookieBannerEvent('accept_all')
}
</script>

<template>
  <div
    v-for="(section, index) in sections"
    :key="section.title"
    class="bg-primitives-white positive-padding border-t lg:hidden"
    :class="{ 'border-b': index === sections.length - 1 }"
  >
    <UIDropdown :id="section.title" :title="section.title">
      <div class="text-light-6 mb-6 flex flex-col gap-6">
        <div
          v-for="link in section.links"
          :key="link.url"
          class="flex items-center"
        >
          <MoleculesLinkWrapper
            anatomy="link"
            :to="link.url"
            :target="link.external ? '_blank' : ''"
            icon-position="right"
            :icon-fade-in="false"
            :link-underline="false"
            :aria-label="link.label"
            :type="
              link.trackId && link.trackId?.includes('cookie-settings')
                ? 'button'
                : 'a'
            "
            @click="onLinkClick(section.title, link.trackId)"
          >
            <template #label>
              {{ link.label }}
            </template>
            <template #iconLink>
              <DefaultIconsGo
                v-if="link.external"
                aria-hidden="true"
                class="!text-primitives-grey-200"
              />
            </template>
          </MoleculesLinkWrapper>
        </div>
      </div>
    </UIDropdown>
  </div>
  <div class="bg-primitives-white text-primitives-black hidden lg:block">
    <div class="grid-standard gap-4 border-y py-16">
      <div
        v-for="(section, index) in sections"
        :key="index"
        class="col-span-3 flex flex-col gap-6"
      >
        <h3 class="text-medium-6 text-primitives-black">
          {{ section.title }}
        </h3>

        <MoleculesLinkWrapper
          v-for="link in section.links"
          :key="link.url"
          anatomy="link"
          :type="
            link.trackId && link.trackId?.includes('cookie-settings')
              ? 'button'
              : 'a'
          "
          :title="
            link.external
              ? `${link.label} - ${$ts('accessibility.externalLink')}`
              : undefined
          "
          class="!text-light-6"
          :underline-effect="'appear'"
          :to="link.url"
          :target="link.external ? '_blank' : ''"
          :aria-label="link.label"
          @click="onLinkClick(section.title, link.trackId)"
        >
          <template #label>
            {{ link.label }}
          </template>
          <template #iconLink>
            <DefaultIconsGo
              v-if="link.external"
              class="!text-primitives-grey-200"
              aria-hidden="true"
            />
          </template>
        </MoleculesLinkWrapper>
      </div>
    </div>
    <CookieBannerCenter
      :cookies
      @on-accept-all="acceptAllCookies"
      @on-save="save"
      @on-toggle-cookie="toggleCookie"
    />
  </div>
</template>
